export const environment = {
  production: true,
  baseUrl: 'https://come2watchtest.go-net.co.il/api/',
  title: 'Test',
  auth: {
    domain: 'dev-8vkrswfu1sutu1gr.us.auth0.com',
    clientId: 'ZI1jHeY8VvXoOXtSWFZZx66khkmxcVHo',
    authorizationParams: {
      redirect_uri: 'https://come2watchtest.go-net.co.il',
    },
  },
};
