import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { UserService } from '../services/user.service';
import { userRole } from '../models/userRole';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  showInfo = false;

  currentUser: any;

  userRoles: userRole[] = [];

  userId = '';

  visible = false;
  position = 'center';

  redirectURL = environment.auth.authorizationParams.redirect_uri;

  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) public document: Document,
    private accountService: UserService
  ) {}

  ngOnInit(): void {
    console.log('Current environment:', environment.title);
    console.log(environment.auth);

    console.log(
      'this is document.location.origin = ' + this.document.location.origin
    );

    this.auth.user$.subscribe((response) => {
      if (response != null) {
        this.currentUser = response;
        console.log(this.currentUser);
        this.userId = this.currentUser.sub;
        this.getRoles(this.userId);
      } else {
        console.log('user not logged in');
      }
    });
  }

  getRoles = (userId: string) => {
    console.log(userId);

    this.accountService.getRoles(userId).subscribe((response: any) => {
      // console.log(response);
      this.userRoles = response;

      console.log(this.userRoles);
    });
  };

  openProfileDialog = (position: string) => {
    this.visible = true;
    this.position = position;
  };
}
