import { Injectable } from '@angular/core';
import { userModel } from '../models/userModel';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl = environment.baseUrl + 'User/';

  constructor(private http: HttpClient) {}

  signup = (userModel: userModel) => {
    return this.http.post(this.baseUrl + 'Signup', userModel);
  };

  login = (userDetails: userModel) => {
    return this.http.post<userModel>(this.baseUrl + 'Login', userDetails);
  };

  getRoles = (userId: string) => {
    return this.http.get(this.baseUrl + `GetRoles/${userId}`);
  };

  isUserSignedup = (userEmail: string) => {
    return this.http.get<userModel>(
      this.baseUrl + `IsUserSignedup?userEmail=` + userEmail
    );
  };

  editUserInfo = (userInfo: userModel) => {
    return this.http.put(this.baseUrl + 'EditUserInfo', userInfo);
  };

  sendAnimal = (userAnimal: string) => {
    return this.http.get(this.baseUrl + `SendAnimalBack/${userAnimal}`);
  };
}
