import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateGameComponent } from './create-game/create-game.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { AllGamesComponent } from './all-games/all-games.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { GameDetailsComponent } from './game-details/game-details.component';
import { GenerateQRComponent } from './generate-qr/generate-qr.component';
import { ErrorPageComponent } from './error-page/error-page.component';

const routes: Routes = [
  { path: '', component: LoginComponent },

  { path: 'signup', component: SignupComponent },
  {
    path: 'createGame',
    component: CreateGameComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'createGame/:userId',
    component: CreateGameComponent,
    canActivate: [AuthGuard],
  },
  { path: 'allGames', component: AllGamesComponent, canActivate: [AuthGuard] },
  {
    path: 'allGames/:userId',
    component: AllGamesComponent,
    canActivate: [AuthGuard],
  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: 'gameDetails/:id',
    component: GameDetailsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'qrcode', component: GenerateQRComponent },
  { path: '**', component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
