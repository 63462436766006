<div class="row justify-content-center make-right">
  <div class="col-md-6">
    <div class="card mt-5">
      <div class="card-header">
        <h3>הרשמה</h3>
      </div>
      <div class="card-body">
        <form #userForm="ngForm" (ngSubmit)="submitForm(userForm)">
          <div class="form-group">
            <label for="firstName">שם פרטי :</label>
            <input
              type="text"
              class="form-control"
              id="firstName"
              placeholder="שם פרטי"
              name="firstName"
              [(ngModel)]="userInfo.FirstName"
              required
            />
          </div>
          <div class="form-group">
            <label for="lastName">שם משפחה :</label>
            <input
              type="text"
              class="form-control"
              id="lastName"
              placeholder="שם משפחה"
              name="lastName"
              [(ngModel)]="userInfo.LastName"
              required
            />
          </div>

          <!-- <div class="form-group">
            <label for="userName"> : בחר שם משתמש</label>
            <input
              type="text"
              class="form-control make-right"
              id="userName"
              placeholder="שם משתמש באנגלית בלבד"
              name="userName"
              ngModel
              #userName="ngModel"
              pattern="^(?=.*[a-zA-Z])[a-zA-Z0-9]*$"
              required
            />
          </div> -->

          <div class="form-group">
            <label for="email">אימייל :</label>
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="אימייל"
              name="UserEmail"
              [(ngModel)]="userEmail"
              readonly
            />
          </div>
          <div class="form-group">
            <label for="phone">מספר פלאפון :</label>
            <input
              type="text"
              class="form-control"
              id="phone"
              placeholder="מספר פלא (מספרים בלבד)"
              name="UserPhone"
              [(ngModel)]="userInfo.UserPhone"
              required
              pattern="[0-9]*"
            />
          </div>
          <div class="form-group">
            <label for="address">כתובת :</label>
            <input
              type="text"
              class="form-control"
              id="address"
              placeholder="כתובת (לא חובה)"
              name="address"
              [(ngModel)]="userInfo.Address"
            />
          </div>
          <br />
          <button
            *ngIf="!editInfoEnable"
            type="submit"
            class="btn btn-primary"
            [disabled]="!userForm.valid"
          >
            הירשם עכשיו
          </button>
          <button
            *ngIf="editInfoEnable"
            type="button"
            (click)="editUserInfo(userForm)"
            class="btn btn-primary"
            [disabled]="!userForm.valid"
          >
            ערוך מידע
          </button>
        </form>
        <br />
        <ul class="custom-ul">
          <li>פרטים אלה ישמשו לצורך מעקב וחלוקת פרסים</li>
        </ul>
      </div>
    </div>
  </div>
</div>
