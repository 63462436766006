import { Component, OnInit } from '@angular/core';
import { GameService } from '../services/game.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { userRole } from '../models/userRole';
import { Router } from '@angular/router';
import * as QRCodeGenerator from 'qrcode-generator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-games',
  templateUrl: './all-games.component.html',
  styleUrls: ['./all-games.component.css'],
})
export class AllGamesComponent implements OnInit {
  gamesList: any;

  userId: any;

  userRoles: userRole[] = [];

  isAdmin = false;

  qrCodeImageUrl: string = '';
  qrCodeUrl: string = 'https://www.facebook.com/ShahafKrinitz'; // The URL you want to encod

  baseUrl = environment.baseUrl;

  constructor(
    private gameService: GameService,
    private route: ActivatedRoute,
    private accountService: UserService,
    private router: Router
  ) {
    this.userId = this.route.snapshot.paramMap.get('userId');
  }

  ngOnInit(): void {
    console.log(this.userId);

    if (!this.userId) {
      console.log('no value in userId');
      return;
    }

    this.accountService.getRoles(this.userId).subscribe((response: any) => {
      this.userRoles = response;
      console.log(this.userRoles);

      if (this.userRoles.length === 0) {
        console.log('No roles');
      } else {
        console.log(this.userRoles);

        // Check if the user has an 'Admin' role
        this.userRoles.forEach((role) => {
          console.log(role);
          if (role.name == 'Admin') {
            this.isAdmin = true;
          }
        });
      }
    });

    this.getAllGames();
  }

  goToCreateGame = () => {
    this.router.navigate(['/createGame', this.userId]);
  };

  getAllGames = () => {
    this.gameService.getAllGames().subscribe((response) => {
      this.gamesList = response;
      console.log(this.gamesList);

      // After fetching games, generate QR codes
      this.generateQRCodes();
    });
  };

  goToGameDetails = (gameId: number) => {
    this.router.navigate(['/gameDetails', gameId]);
  };

  generateQRCodes(): void {
    const typeNumber = 0;
    const errorCorrectionLevel = 'L';

    for (const game of this.gamesList) {
      const gamePath = `/gameDetails/${game.GameId}`;
      const fullUrl = this.baseUrl + gamePath;
      const qr = QRCodeGenerator(typeNumber, errorCorrectionLevel);
      qr.addData(fullUrl);
      qr.make();
      game.qrCodeImageUrl = qr.createDataURL();
    }
  }

  deleteGame = (gameId: number) => {
    console.log(gameId);
    this.gameService.deleteGame(gameId).subscribe((response) => {
      console.log(response);

      if (response) {
        alert('המשחק נמחק בהצלחה!');
        this.getAllGames();
      } else {
        alert('המשחק לא יכול להימחק כרגע, צור קשר עם ההנהלה.');
      }
    });
  };
}
