import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameService } from '../services/game.service';
import { gameModel } from '../models/gameModel';
import { AuthService } from '@auth0/auth0-angular';
import { userSignupToGameInfo } from '../models/userSignupToGameInfo';

@Component({
  selector: 'app-game-details',
  templateUrl: './game-details.component.html',
  styleUrls: ['./game-details.component.css'],
})
export class GameDetailsComponent implements OnInit {
  gameId = 0;
  gameDetails!: gameModel;

  currentUser: any;
  userEmail = '';

  isTheGameToday = false;

  constructor(
    private route: ActivatedRoute,
    private gameService: GameService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.auth.user$.subscribe((response) => {
      this.currentUser = response;

      
      console.log(this.currentUser);

      this.userEmail = this.currentUser.email;
      console.log(this.userEmail);
    });

    this.route.params.subscribe((params) => {
      this.gameId = +params['id']; // '+' to convert it to a number
    });
    console.log(this.gameId);
    this.getGameById(this.gameId);
  }

  getGameById = (gameId: number) => {
    this.gameService.getGameById(gameId).subscribe((response: gameModel) => {
      this.gameDetails = response;
      console.log(this.gameDetails);
      this.checkGameDate();
    });
  };

  signupToGame = () => {
    let userSignupToGameInfo: userSignupToGameInfo = {
      UserEmail: this.userEmail,
      GameId: this.gameId,
      SignupDate: new Date(),
    };

    this.gameService
      .signupToGame(userSignupToGameInfo)
      .subscribe((response) => {
        console.log(response);

        if (response == true) {
          alert('נירשמת למשחק בהצלחה !');
        } else {
          alert('הנך כבר רשום למשחק זה');
        }
      });
  };

  // checkGameDate = () => {
  //   let todayDate = new Date();
  //   console.log(todayDate);
  //   console.log(this.gameDetails.GameDate);
  // };

  checkGameDate = () => {
    let todayDate = new Date();
    let gameDate = new Date(this.gameDetails.GameDate);

    // Format todayDate as 'dd/mm/yyyy' or 'dd-mm-yyyy'
    const todayFormatted = todayDate.toLocaleDateString('en-GB'); // Adjust 'en-GB' as needed

    // Format gameDate as 'dd/mm/yyyy' or 'dd-mm-yyyy'
    const gameFormatted = gameDate.toLocaleDateString('en-GB'); // Adjust 'en-GB' as needed

    console.log(todayFormatted);
    console.log(gameFormatted);

    if (todayFormatted == gameFormatted) {
      this.isTheGameToday = true;
    }
  };
}
