import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { gameModel } from '../models/gameModel';
import { userSignupToGameInfo } from '../models/userSignupToGameInfo';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  baseUrl = environment.baseUrl + 'Game/';

  constructor(private http: HttpClient) {}

  createGame = (game: gameModel) => {
    return this.http.post(this.baseUrl + 'CreateGame', game);
  };

  getAllGames = () => {
    return this.http.get(this.baseUrl + 'GetAllGames');
  };

  getGameById = (gameId: number) => {
    return this.http.get<gameModel>(this.baseUrl + 'GetGameById/' + gameId);
  };

  signupToGame = (userSignupToGameInfo: userSignupToGameInfo) => {
    return this.http.post(this.baseUrl + 'SignupToGame', userSignupToGameInfo);
  };

  deleteGame = (gameId: number) => {
    return this.http.get(this.baseUrl + `DeleteGame/${gameId}`);
  };
}
