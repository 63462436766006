<div class="make-right game-details-container">
  <h3 class="game-details-title">פרטי המשחק :</h3>

  <hr />

  <div class="game-info" *ngIf="gameDetails">
    <div class="game-detail">
      <span class="game-detail-label">קוד המשחק:</span>
      <span class="game-detail-value"> {{ gameDetails.GameId }}</span>
    </div>

    <div class="game-detail">
      <span class="game-detail-label">שם המשחק:</span>
      <span class="game-detail-value"> {{ gameDetails.GameName }}</span>
    </div>

    <div class="game-detail">
      <span class="game-detail-label">מיקום המשחק:</span>
      <span class="game-detail-value"> {{ gameDetails.GameLocation }}</span>
    </div>

    <div class="game-detail">
      <span class="game-detail-label">תאריך המשחק: </span>
      <span class="game-detail-value">
        {{ gameDetails.GameDate | date : "dd/MM/yyyy" }}</span
      >
    </div>

    <div class="game-detail">
      <span class="game-detail-label">שעת תחילת המשחק:</span>
      <span class="game-detail-value"> {{ gameDetails.GameStartHour }}</span>
    </div>

    <div class="game-detail">
      <span class="game-detail-label">הערות למשחק:</span>
      <span class="game-detail-value"> {{ gameDetails.Notes }}</span>
    </div>

    <br /><br />

    <div *ngIf="isTheGameToday" class="gameToday">
      המשחק היום! הנך יכול להירשם עכשיו !
    </div>

    <div *ngIf="!isTheGameToday" class="gameNotToday">
      ניתן להירשם למשחק רק ביום המשחק !
    </div>

    <br />

    <button
      [disabled]="!isTheGameToday"
      class="btn btn-success"
      (click)="signupToGame()"
    >
      הירשם למשחק!
    </button>
  </div>
</div>

<!-- <button (click)="checkGameDate()">test</button> -->
