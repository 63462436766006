import { Component, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { userModel } from '../models/userModel';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  currentUser: any;

  userEmail = '';

  userInfo: userModel = {
    FirstName: '',
    LastName: '',
    UserEmail: this.userEmail,
    UserPhone: '',
    Address: '',
  };

  editInfoEnable = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.auth.user$.subscribe((response) => {
      this.currentUser = response;
      console.log(this.currentUser);
      console.log(this.currentUser.email);

      this.userEmail = this.currentUser.email;

      this.isUserSignedup(this.userEmail);
    });
  }

  submitForm = (userForm: NgForm) => {
    // console.log(userForm.value);

    let newUser: userModel;
    newUser = userForm.value;
    newUser.CreateDate = new Date();

    console.log(newUser);

    this.userService.signup(newUser).subscribe((response) => {
      console.log(response);

      if (response == true) {
        alert('המשתמש נוסף בהצלחה !');
        userForm.resetForm();
      } else {
        alert('קיים כבר משתמש עם האימייל הזה');
      }
    });
  };

  isUserSignedup = (userEmail: string) => {
    this.userService.isUserSignedup(userEmail).subscribe((response) => {
      // console.log(response);

      if (response != null) {
        this.userInfo = response;
        console.log(this.userInfo);
        this.editInfoEnable = true;
      }
    });
  };

  editUserInfo = (userForm: NgForm) => {
    // console.log(userForm.value);

    let editUserInfo: userModel;
    editUserInfo = userForm.value;

    console.log(editUserInfo);
    this.userService.editUserInfo(editUserInfo).subscribe((response) => {
      console.log(response);
      alert('השינוי בוצע בהצלחה!');
    });
  };
}
