import { Component } from '@angular/core';
import * as QRCodeGenerator from 'qrcode-generator';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-generate-qr',
  templateUrl: './generate-qr.component.html',
  styleUrls: ['./generate-qr.component.css'],
})
export class GenerateQRComponent {
  qrCodeImageUrl: string = '';
  qrCodeUrl: string = 'https://www.facebook.com/ShahafKrinitz'; // The URL you want to encod

  userAnimal = '';

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.generateQRCode();
  }

  generateQRCode(): void {
    if (!this.qrCodeUrl) {
      alert('please add a url');
      return; // Prevent generating if no URL is entered
    }

    const typeNumber = 0;
    const errorCorrectionLevel = 'L';
    const qr = QRCodeGenerator(typeNumber, errorCorrectionLevel);
    qr.addData(this.qrCodeUrl);
    qr.make();
    this.qrCodeImageUrl = qr.createDataURL();
  }

  sendAnimal = (userAnimal: string) => {
    this.userService.sendAnimal(userAnimal).subscribe((response) => {
      console.log(response);
    });
  };
}
