<div class="make-right">
  <h2>ברוך הבא לאתר הבית שלנו !</h2>
  <p>
    לאחר שנירשמת אצלנו לאתר אנו צריכים שתמלא עוד מספר פרטים על מנת שנוכל שנוכל
    להכיר קצת יותר טוב וכדאי שנדע לאיפה לשלוח את הפרסים שלך!
  </p>

  <br />

  <h4>עבור לעמוד הרשמה</h4>
  <button class="btn btn-primary" (click)="goToSignup()">הירשם עכשיו!</button>
</div>
