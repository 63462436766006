<div class="make-right text-center">
  <h2>ברוך הבא לאתר שלנו - <strong>עידוד השתתפות!</strong></h2>
  <p>
    על מנת להירשם למשחקים עלייך להתחבר לאתר שלנו על ידי לחיצה על הכפתור למטה
  </p>
  <br />
  <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
    <button
      class="btn btn-danger"
      (click)="
        auth.logout({ logoutParams: { returnTo: document.location.origin } })
      "
    >
      התנתק
    </button>
  </ng-container>

  <ng-template #loggedOut>
    <button class="btn btn-success" (click)="auth.loginWithRedirect()">
      התחבר עכשיו
    </button>
  </ng-template>

  <!-- <p>עוד לא נירשמת? הירשם כאן למטה</p>

  <a [routerLink]="['/signup']">
    <button class="btn btn-primary">הירשם עכשיו</button>
  </a>
</div> -->

  <!-- <div class="row justify-content-center make-right">
  <div class="col-md-6">
    <div class="card mt-5">
      <div class="card-header">
        <h3>התחבר</h3>
      </div>
      <div class="card-body">
        <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
          <div class="form-group">
            <label for="userName">שם משתמש : </label>
            <input
              type="text"
              class="form-control"
              id="userName"
              placeholder="שם משתמש"
              name="userName"
              ngModel
              required
            />
          </div>
          <div class="form-group">
            <label for="password">סיסמא : </label>
            <input
              type="password"
              class="form-control my-input"
              id="password"
              placeholder="סיסמא"
              name="password"
              ngModel
              required
            />
          </div>
          <br />
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="!loginForm.valid"
          >
            התחבר !
          </button>
        </form>
      </div>
    </div>
    <br />

  
  </div>
</div> -->
</div>
