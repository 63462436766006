import { Component, OnInit } from '@angular/core';
import { gameModel } from '../models/gameModel';
import { NgForm } from '@angular/forms';
import { UserService } from '../services/user.service';
import { GameService } from '../services/game.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { userRole } from '../models/userRole';

@Component({
  selector: 'app-create-game',
  templateUrl: './create-game.component.html',
  styleUrls: ['./create-game.component.css'],
})
export class CreateGameComponent implements OnInit {
  date: Date = new Date(2023, 0, 1);

  userId = '';

  userRoles: userRole[] = [];

  isAdmin = false;

  constructor(
    private gameService: GameService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const userId = params['userId'];

      if (!userId) {
        return; // Exit early if userId is empty
      }

      this.userService.getRoles(userId).subscribe((res: any) => {
        this.userRoles = res;

        if (this.userRoles.length === 0) {
          console.log('No roles');
        } else {
          console.log(this.userRoles);

          // Check if the user has an 'Admin' role
          this.userRoles.forEach((role) => {
            console.log(role);
            if (role.name == 'Admin') {
              this.isAdmin = true;
            }
          });
        }
      });
    });
  }

  subimtForm = (gameForm: NgForm) => {
    // console.log(gameForm.value);

    let game: gameModel;

    game = gameForm.value;

    console.log(game);

    console.log(game.GameDate);

    this.gameService.createGame(game).subscribe((res) => {
      console.log(res);

      if (res) {
        alert('המחשק נוצר בהצלחה !');
        gameForm.resetForm();
        this.router.navigate(['/']);
      } else {
        alert('משהו השתבש, נסה שוב');
      }
    });
  };
}
