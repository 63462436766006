<div class="make-right">
  <nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    style="padding-right: 30px"
  >
    <a class="navbar-brand" [routerLink]="['/home']">Home</a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/allGames']">All Games</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/qrcode']">qrcode</a>
        </li>
      </ul>
    </div>
  </nav>

  <button class="btn btn-info AsiStyle" (click)="openProfileDialog('top')">
    פרופיל
    <mat-icon>person</mat-icon>
  </button>

  <!-- dialog details  -->
  <p-dialog
    class="make-right"
    header="פרטי המשתמש : "
    [(visible)]="visible"
    [modal]="true"
    [draggable]="false"
    [resizable]="true"
    [style]="{ width: '50vw' }"
    [position]="position"
  >
    <!-- if the user is logged in he will come here -  -->
    <div class="text-center" *ngIf="currentUser">
      <p>
        שם : {{ currentUser.name }}
        <br />
        אימייל : {{ currentUser.email }}
      </p>
      <img src="{{ currentUser.picture }}" alt="user picture" />

      <br /><br />

      <div class="roleAndDesc" *ngIf="userRoles && userRoles.length > 0">
        <ul>
          <li *ngFor="let role of userRoles">
            <h3>תפקיד : {{ role.name }}</h3>
            <p>תיאור התפקיד : {{ role.description }}</p>
          </li>
        </ul>
      </div>

      <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
        <button
          class="btn btn-danger mt-3"
          (click)="
            auth.logout({
              logoutParams: { returnTo: document.location.origin }
            })
          "
        >
          התנתק
        </button>
      </ng-container>

      <ng-template #loggedOut>
        <button class="btn btn-success mt-3" (click)="auth.loginWithRedirect()">
          התחבר
        </button>
      </ng-template>
    </div>

    <!-- if the user logged out he will see this -  -->

    <div class="text-center" *ngIf="!currentUser">
      <h5>בבקשה התחבר :</h5>
      <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
        <button
          class="btn btn-danger mt-3"
          (click)="
            auth.logout({
              logoutParams: { returnTo: document.location.origin }
            })
          "
        >
          התנתק
        </button>
      </ng-container>

      <ng-template #loggedOut>
        <button class="btn btn-success mt-3" (click)="auth.loginWithRedirect()">
          התחבר
        </button>
      </ng-template>
    </div>

    <ng-template pTemplate="footer">
      <p-button
        icon="pi pi-check"
        (click)="visible = false"
        label="אישור"
        styleClass="p-button-text"
      ></p-button>
    </ng-template>
  </p-dialog>
</div>
