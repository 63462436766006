import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from '../services/user.service';
import { userModel } from '../models/userModel';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { userRole } from '../models/userRole';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  currentUser: any;
  cureentUserInfo!: userModel;
  userId = '';

  userRoles: userRole[] = [];

  isAdmin = false;

  constructor(
    private accountService: UserService,
    private router: Router,
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.auth.user$.subscribe((response) => {
      if (response == null) {
        console.log('user not logged in');
      } else {
        this.currentUser = response;
        console.log(this.currentUser);
        this.userId = this.currentUser.sub;

        this.accountService.getRoles(this.userId).subscribe((response: any) => {
          this.userRoles = response;

          if (this.userRoles.length === 0) {
            console.log('no roles');
            this.router.navigate(['/home']);
          } else {
            console.log(this.userRoles);
            // Loop through userRoles and log each object
            this.userRoles.forEach((role) => {
              console.log(role);
              if (role.name === 'Admin') {
                this.isAdmin = true;

                this.router.navigate(['/allGames', this.userId]);

                // this.router.navigate(['/allGames'], {
                //   queryParams: { userId: this.userId },
                // });
              } else {
                this.router.navigate(['/home']);
              }
            });
          }
        });
      }
    });
  }

  // login = (loginForm: NgForm) => {
  //   // console.log(loginForm.value);

  //   let userDetails: userModel;
  //   userDetails = loginForm.value;

  //   this.accountService.login(userDetails).subscribe((response: userModel) => {
  //     if (response) {
  //       this.cureentUserInfo = response;

  //       if (this.cureentUserInfo.IsManagement) {
  //         this.router.navigate(['/allGames']);
  //       } else {
  //         this.router.navigate(['/home']);
  //       }

  //       console.log(this.cureentUserInfo);

  //       // this.router.navigate(['/allGames']);
  //     } else {
  //       alert('שם משתמש או סיסמא אינם נכונים.');
  //       loginForm.resetForm();
  //     }
  //     // console.log(response);
  //   });
  // };
}
