<div class="make-right">
  <div class="onlyForAdmin" *ngIf="isAdmin">
   
      <button (click)="goToCreateGame()" class="btn btn-primary">צור משחק חדש</button>
  

    <br /><br />

    <h2 class="text-center mb-4">כל המשחקים</h2>

    <div class="table-responsive">
      <table class="table table-striped table-bordered">
        <thead class="thead-dark">
          <tr>
            <th>מספר המשחק</th>
            <th>שם המשחק</th>
            <th>מיקום המשחק</th>
            <th>תאריך המשחק</th>
            <th>שעת תחילת המשחק</th>
            <th>הערות</th>
            <th>קישור למשחק</th>
            <th>צור QR</th>
            <th>מחק משחק</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let game of gamesList">
            <td>{{ game.GameId }}</td>
            <td>{{ game.GameName }}</td>
            <td>{{ game.GameLocation }}</td>
            <td>{{ game.GameDate | date : "dd/MM/yyyy" }}</td>
            <td>{{ game.GameStartHour }}</td>
            <td>{{ game.Notes }}</td>
            <td
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <button
                class="btn btn-primary"
                (click)="goToGameDetails(game.GameId)"
              >
                עבור למשחק
              </button>
            </td>
            <td>
              <img
                class="qr-code-container"
                *ngIf="game.qrCodeImageUrl"
                [src]="game.qrCodeImageUrl"
                alt="QR Code"
              />
            </td>
            <td><button class="btn btn-danger" (click)="deleteGame(game.GameId)" >מחק משחק</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="notAdmin" *ngIf="!isAdmin">
    <h3>this page is for Admins Only !!!</h3>
  </div>
</div>
