<p>enter a url to check</p>

<div class="url-input">
  <input [(ngModel)]="qrCodeUrl" placeholder="Enter URL" />
  <button class="btn btn-primary" (click)="generateQRCode()">
    Generate QR Code
  </button>
</div>

<div class="qr-code-container">
  <img *ngIf="qrCodeImageUrl" [src]="qrCodeImageUrl" alt="QR Code" />
</div>


<div class="test">
  <label>please enter a animal</label>
  <input type="text" [(ngModel)]="userAnimal">
</div>

<button (click)="sendAnimal(userAnimal)">send it</button>