import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateGameComponent } from './create-game/create-game.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { SignupComponent } from './signup/signup.component';
import { AllGamesComponent } from './all-games/all-games.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';

// primeNg
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';

// Angular Matirial
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';

// Auth0
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from './header/header.component';
import { GameDetailsComponent } from './game-details/game-details.component';
import { GenerateQRComponent } from './generate-qr/generate-qr.component';
import { ErrorPageComponent } from './error-page/error-page.component';

@NgModule({
  declarations: [
    AppComponent,
    CreateGameComponent,
    LoginComponent,
    SignupComponent,
    AllGamesComponent,
    HomeComponent,
    HeaderComponent,
    GameDetailsComponent,
    GenerateQRComponent,
    ErrorPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CalendarModule,
    DialogModule,
    MatIconModule,
    AuthModule.forRoot(environment.auth),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
