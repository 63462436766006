<div class="forAdmin" *ngIf="isAdmin">
  <h1>צור משחק חדש</h1>

  <div class="form-container">
    <form #gameForm="ngForm" (ngSubmit)="subimtForm(gameForm)">
      <label class="form-label" for="gameName"> : שם המשחק</label>
      <input
        type="text"
        class="form-input"
        id="gameName"
        name="gameName"
        ngModel
        required
      />

      <label class="form-label" for="gameLocation"> : מקום המשחק</label>
      <input
        type="text"
        class="form-input"
        id="gameLocation"
        name="gameLocation"
        ngModel
        required
      />

      <label class="form-label" for="gameDate"> : תאריך המשחק</label>
      <p-calendar
        [(ngModel)]="date"
        dateFormat="dd.mm.yy"
        [showIcon]="true"
        id="gameDate"
        name="gameDate"
        ngModel
      ></p-calendar>

      <label class="form-label" for="startHour"> : שעת תחילת המשחק</label>
      <input
        type="text"
        class="form-input"
        id="startHour"
        name="GameStartHour"
        ngModel
        pattern="^[0-9:]+$"
        required
      />

      <label class="form-label" for="notes"> : הערות</label>
      <input
        type="text"
        class="form-input"
        id="notes"
        name="notes"
        ngModel
        required
      />

      <button
        type="submit"
        class="btn btn-success"
        type="submit"
        [disabled]="!gameForm.valid"
      >
        : צור משחק
      </button>
    </form>
  </div>
</div>

<div class="notAdmin" *ngIf="!isAdmin">
  <h3>you are not admin! please contact manager</h3>
</div>
